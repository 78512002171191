import React from 'react'
import { graphql } from 'gatsby'
import GiftWarranty from 'src/components/GiftWarranty'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'

const Page = () => {
  return (
    <>
      <GatsbySeoCustom
        title="Vivara, Jornada do Presenteado!"
        description="Gift Warranty"
        language="pt-BR"
        noindex
        nofollow
      />

      <GiftWarranty />
    </>
  )
}

export const query = graphql`
  query GiftWarrantyPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
