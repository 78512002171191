/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import type { CustomArrowProps } from 'react-slick'

interface Props extends React.HTMLProps<HTMLDivElement> {
  onClick?: () => void
  width?: string
  height?: string
  backgroundColor?: string
  stroke?: string
  viewBox?: string
  pathD?: string
  border?: string
}

const LeftArrowBlack = ({
  onClick,
  width = '13',
  height = '22',
  backgroundColor,
  stroke = 'black',
  viewBox = '0 0 13 22',
  pathD = 'M12 21L2 11L12 1.00458',
  border = '',
  ...props
}: Props) => {
  const { currentSlide, slideCount, style, ...restProps } =
    props as CustomArrowProps

  return (
    <div
      className="shelf-arrow-left-container"
      onClick={onClick}
      onKeyDown={onClick}
      style={{
        ...style,
        backgroundColor,
        border: border ? `1px solid ${border}` : 'none',
      }}
      {...restProps}
    >
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="shelf-arrow-left"
      >
        <path d={pathD} stroke={stroke} strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  )
}

export default LeftArrowBlack
